/* MyCalendar.css */

/* Set the background color of the calendar */
.rbc-calendar {
    background-color: #f0f0f0;
    /* Light gray background */
}

/* Style the selected date */
.rbc-selected {
    background-color: #007bff !important;
    /* Blue background for selected date */
    color: white !important;
    /* White text for selected date */
}

/* Style for the dates in the unchosen month */
.rbc-day-bg.rbc-off-range-bg {
    background: #00000033;
    /* Light black background for unchosen month dates */
    color: #000000 !important;
    /* Black text for unchosen month dates */
}

/* Style for the dates in the current month */
.rbc-date-cell {
    color: black !important;
    /* Black text for current month dates */
}