.popup {
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0,0,0,0.4);
    }
button.a1 {
        background-color: blue;
        color: white;
      }
      
button.a1:active {
        background-color: green;
      }
    .popup-inner {
    background-color: white;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75);
    width: 40%;
    }
    
    .popup-inner h2 {
    margin-top: 0;
    }
    
    .popup-inner label {
    display: block;
    margin-bottom: 10px;
    }
    
    .popup-inner input {
    width: 100%;
    padding: 5px;
    border-radius: 5px;
    border: 1px solid #ccc;
    margin-bottom: 10px;
    }
    
    .popup-inner button[type="submit"],
    .popup-inner button[type="button"] {
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin-right: 10px;
    cursor: pointer;
    border-radius: 5px;
    }
    
    .popup-inner button[type="submit"]:hover,
    .popup-inner button[type="button"]:hover {
    background-color: #45a049;
    }
    
    .popup-inner button[type="submit"] {
    margin-top: 20px;
    }
    
    .popup-inner button[type="button"] {
    margin-top: 20px;
    }
    
    .popup-inner button[type="button"]:hover {
    background-color: #3f78ad;
    }
    .popup-inner button[type="button"]:focus {
        background-color: #1a7ad3;
        }
    
    .popup-inner button[type="submit"]:disabled {
    background-color: #bfbfbf;
    color: #ffffff;
    cursor: not-allowed;
    }
    
    .popup-inner button[type="button"]:disabled {
    background-color: #bfbfbf;
    color: #ffffff;
    cursor: not-allowed;
    }
    .flex {
        display: flex;
      }
      
      .gap-2 {
        gap: 8px;
      }
      
      .bg-blue-300 {
        background: rgb(147 197 253);
      }
      
      .bg-blue-500 {
        background: rgb(59 130 246);
      }
      
      .text-white {
        color: rgb(255 255 255);
      }