div.logo {
    width: 80px;
    height: 60px;
    background-image: url("../../images/logo.png");
    background-repeat: no-repeat;
    background-size: 100%;
  }

  div.red {
    width: 80px;
    height: 60px;
    background-color: red;
  }