@tailwind base;
@tailwind components;
@tailwind utilities;



thead{
    align-self: center;
}
table {
    border-collapse: collapse;
    width: 100%;
    margin-top: 20px; /* Adjust margin as needed */
  }
  
th,td {
    border: 1px solid #dddddd;
    border-left: none;
    border-right: none;
    text-align: center;
    padding: 8px;
  }
  
  h2{
    margin-right: 2%;
  }

button.center{
    justify-items:center;
  }
button.navv::after{
    background-color: #9faade;
  }
tr:nth-child(even) {
    background-color: #1b1a25;

  }
tr:nth-child(even).aa{
        background-color: #efeff4;
  }
    tr:nth-child(even).bbb {
      background-color: #f8a6a6;
    }
        tr:nth-child(even).bbb {
          background-color: #f8a6a6;
        }
    tr:nth-child(odd).aa {
      background-color: #ccccd8;
    }
tr:hover {
    background-color: #363447;
  }
/* td:hover {
    background-color: #605b8f;
  } */
  
tr {
    border-bottom: none; /* Remove bottom border from last row */
  }
  