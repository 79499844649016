
div.tooth-chart {
  justify-content: right;
  align-items: flex-start; /* Align content to the top */
  height: 100vh;
}
div.child-tooth-chart{

}
g.child-outlines {
  fill: #4f4fc0; /* Example CSS styling */
  stroke: rgb(199, 36, 36);
  justify-content: center;
  align-items: flex-start;
}
.tooth-chart{
  width:550px;
  position: relative;
}
.child-tooth-chart{
  position: absolute;
  width: 250px;
  top: 250px;
  left: 140px;
}

#Spots{
  polygon, path{
    -webkit-transition:fill .25s;
    transition:fill .25s;
  }
  polygon:hover, polygon:active, path:hover, path:active{
    fill:#dddddd !important;
  }
}
polygon:hover, polygon:active, path:hover, path:active{
  fill:#dddddd !important;
}
polygon, path{
  -webkit-transition:fill .25s;
  transition:fill .25s;
}

.parent {
  margin: 1rem;
  padding: 2rem 2rem;
  text-align: right;
  justify-content: right;
  white-space: nowrap;
}
.child1 {
  justify-content: right;
  direction: rtl;
  align-items: flex-start;
  display: inline-block;
  border: 1px solid red;
  padding: 1rem 1rem;
  vertical-align: top;
  white-space: nowrap;
  width: 35%;
}
.child2 {
  justify-content: right;
  direction: rtl;
  align-items: flex-start;
  display: inline-block;
  border: 1px solid red;
  padding: 1rem 1rem;
  vertical-align: top;
  float: left;
}